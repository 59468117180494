<script>
import pagesService from '@/services/pages.service'

export default {
  name: 'Pages',
	data() {
		return {
      items: [],
      headerFields: [
        "__slot:checkboxes",
        {
          name: "published",
          label: this.$t('published'),
          customElement: "published"
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo"
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title"
        },
        {
          name: "changed_at",
          label: this.$t('lastUpdated'),
          format: date => new Date(date).toLocaleString('en-GB')
        },
        "__slot:actions:edit",
        "__slot:actions:delete"
      ],
		}
  },

	mounted() {
    this.getAll()
	},
	methods: {
    getAll(resetOffset = true) {
      if(resetOffset) this.offset = 1
			pagesService.getAllPosts().then(
				res => {
          this.items = res.data.map(item => {
            item.changed_at = item.updated_at || item.created_at
            return item
          })
				}
			)
    },
    remove(id) {
      pagesService.remove(id)
        .then(success => {
          this.$notify({
              message: success.data.message,
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link :to="{ name: 'singlePage', params: { id: 'new' } }">
            <BaseButton 
              type="green"
              gradient
            >
              Create Page
            </BaseButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <BaseListTable
        :items="items"
        :header-fields="headerFields"
        type="pages"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
